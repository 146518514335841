<div class="card-container" [class.no-button]="!buttonLabel">
  <div class="header-container">
    <div class="number">{{ number }}</div>
    <div class="title" [innerHTML]="title"></div>
  </div>

  <div class="imgs-container">
    <ng-content select="[slot=imgs]"></ng-content>
  </div>

  @if (buttonLabel) {
    <div class="footer-container">
      <app-button data-cy="how-to-use-card-btn" [expand]="'default'" [label]="buttonLabel" (clicked)="clicked.emit()" [size]="'small'" />
    </div>
  }
</div>
