import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { ButtonComponent } from '../../../../components/button/button.component';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-how-to-use-card',
  templateUrl: './how-to-use-card.component.html',
  styleUrls: ['./how-to-use-card.component.scss'],
  standalone: true,
  imports: [ButtonComponent],
})
export class HowToUseCardComponent {
  @Input({ required: true }) number!: string;
  @Input({ required: true }) title!: string;
  @Input() buttonLabel!: string;

  @Output() clicked: EventEmitter<void> = new EventEmitter<void>();
}
